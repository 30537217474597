<template>
  <div>
    <div class="buttons">
      <b-button
        type="is-primary"
        :outlined="year !== selectedYear"
        v-for="year in lastFiveYears"
        :key="year"
        @click="getCarExpensesChartData(year)"
        >{{ year }}</b-button
      >
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon icon="cash"></b-icon>
          {{
            this.carIds
              ? `All expenses for cars #${this.carIds}`
              : 'All expenses for each car'
          }}
        </p>
      </header>
      <div class="card-content">
        <line-chart
          v-if="datacollection !== null"
          :chart-data="datacollection"
          :options="options"
        ></line-chart>
        <p v-if="isChartEmpty">No expenses for year {{ selectedYear }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import LineChart from '@/charts/LineChart.js'
import { httpClient } from '@/api/httpClient.js'

export default {
  components: {
    LineChart
  },
  props: {
    carIds: {
      type: Array
    }
  },
  data() {
    return {
      selectedYear: 2020,
      datacollection: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label: (tooltipItems, data) => {
              const carData = data.datasets[tooltipItems.datasetIndex]
              const licensePlate = carData.label
              const carCost = carData.data[tooltipItems.index]

              return `${licensePlate}: ${carCost} €`
            }
          }
        },
        hover: {
          mode: 'nearest',
          intersect: true
        },
        scales: {
          xAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Month'
              }
            }
          ],
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Cost (€)'
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    lastFiveYears() {
      const currentYear = new Date().getFullYear()
      const arr = []
      for (let i = 0; i < 5; i++) {
        arr.push(currentYear - i)
      }

      return arr
    },
    isChartEmpty() {
      return this.datacollection === null
    }
  },
  mounted() {
    this.getCarExpensesChartData()
  },
  methods: {
    getCarExpensesChartData(year = new Date().getFullYear()) {
      if (!this.carIds) {
        this.getCarExpensesForAllUserCars(year)
      } else {
        this.getCarExpensesForSelectedCars(year)
      }
    },
    serializeDataset(carExpenses) {
      if (!carExpenses || !carExpenses.length) return

      let datasetData = []

      for (let i = 1; i < 13; i++) {
        const monthsExpenses = carExpenses.find(
          expense => parseInt(expense.month, 10) === i
        )
        if (monthsExpenses) {
          datasetData.push(parseInt(monthsExpenses.monthlySum, 10))
        } else {
          datasetData.push(0)
        }
      }

      const color =
        'hsl(' +
        360 * Math.random() +
        ',' +
        (25 + 70 * Math.random()) +
        '%,' +
        (70 + 10 * Math.random()) +
        '%)'
      return {
        label: carExpenses[0].licensePlate,
        data: datasetData,
        fill: false,
        backgroundColor: color,
        borderColor: color,
        pointBackgroundColor: 'grey'
      }
    },
    getCarExpensesForAllUserCars(year) {
      const url = `/stats/expenses?year=${year}`
      httpClient
        .get(url)
        .then(res => {
          if (!res.data || !res.data.length) {
            this.datacollection = null
            return
          }
          this.selectedYear = year

          const datasets = res.data
            .map(this.serializeDataset)
            .filter(dataset => typeof dataset === 'object')
          this.datacollection = {
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ],
            datasets
          }
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    getCarExpensesForSelectedCars(year) {
      const url = this.carIds.map(carId =>
        httpClient.get(`/stats/expenses/${carId}?year=${year}`)
      )
      Promise.all(url)
        .then(values => values.map(value => value.data))
        .then(values => values.flat())
        .then(res => {
          const datasets = res
            .map(this.serializeDataset)
            .filter(dataset => typeof dataset === 'object')
          this.datacollection = {
            labels: [
              'Jan',
              'Feb',
              'Mar',
              'Apr',
              'May',
              'Jun',
              'Jul',
              'Aug',
              'Sep',
              'Oct',
              'Nov',
              'Dec'
            ],
            datasets
          }
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    }
  }
}
</script>

<template>
  <div>
    <div class="buttons">
      <b-button
        type="is-primary"
        :outlined="year !== selectedYear"
        v-for="year in lastFiveYears"
        :key="year"
        @click="fillData(year)"
        >{{ year }}</b-button
      >
    </div>
    <div class="card">
      <header class="card-header">
        <p class="card-header-title">
          <b-icon icon="cash"></b-icon>
          All expenses for car #{{ carId }}
        </p>
      </header>
      <div class="card-content">
        <pie-chart
          v-if="datacollection !== null && !isComparison"
          :chart-data="datacollection"
          :options="optionsPie"
        ></pie-chart>
        <bar-chart
          v-if="datacollection !== null && isComparison"
          :chart-data="datacollection"
          :options="optionsBar"
        ></bar-chart>
        <p v-if="isChartEmpty">No expenses for year {{ selectedYear }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from '@/charts/PieChart.js'
import BarChart from '@/charts/BarChart.js'
import { httpClient } from '@/api/httpClient.js'

export default {
  components: {
    PieChart,
    BarChart
  },
  props: {
    carId: {
      type: String
    },
    isComparison: {
      type: Boolean
    }
  },
  data() {
    return {
      selectedYear: 2020,
      datacollection: null,
      optionsPie: {
        responsive: true,
        maintainAspectRatio: false,
        legend: { position: 'right' },
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, data) => {
              return `${data.labels[tooltipItems.index]} ${
                data.datasets[0].data[tooltipItems.index]
              } €`
            }
          }
        }
      },
      optionsBar: {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          enabled: true,
          mode: 'single',
          callbacks: {
            label: (tooltipItems, data) => {
              return `${data.labels[tooltipItems.index]} ${
                data.datasets[0].data[tooltipItems.index]
              } €`
            }
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              scaleLabel: {
                display: true,
                labelString: 'Cost (€)'
              }
            }
          ]
        }
      }
    }
  },
  computed: {
    lastFiveYears() {
      const currentYear = new Date().getFullYear()
      const arr = []
      for (let i = 0; i < 5; i++) {
        arr.push(currentYear - i)
      }

      return arr
    },
    isChartEmpty() {
      return this.datacollection === null
    }
  },
  mounted() {
    this.fillData()
  },
  methods: {
    fillData(year = new Date().getFullYear()) {
      httpClient
        .get(`/stats/expenses-types/${this.carId}?year=${year}`)
        .then(res => {
          this.selectedYear = year

          if (!res.data || !res.data.length) {
            this.datacollection = null
            return
          }

          const expenses = res.data
          const colors = expenses.map(() => this.getRandomColor())

          this.datacollection = {
            labels: expenses.map(({ expenseType }) => expenseType),
            datasets: [
              {
                label: this.isComparison ? 'Car expenses by types' : null,
                data: expenses.map(({ monthlySum }) =>
                  parseInt(monthlySum, 10)
                ),
                backgroundColor: colors,
                hoverBackgroundColor: colors,
                hoverBorderColor: 'rgba(0, 0, 0, 0.2)'
              }
            ]
          }
        })
        .catch(err => {
          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    getRandomColor() {
      return (
        'hsl(' +
        360 * Math.random() +
        ',' +
        (25 + 70 * Math.random()) +
        '%,' +
        (70 + 10 * Math.random()) +
        '%, 0.5)'
      )
    }
  }
}
</script>

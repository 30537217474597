<template>
  <div>
    <Navbar />

    <b-loading :is-full-page="true" :active.sync="isLoading"></b-loading>

    <PageTitle
      :title="'Car comparison'"
      :subtitle="'Compare expenses between selected cars'"
    />

    <section class="section" v-if="!areCarsAlreadyCompared">
      <div class="container">
        <!-- <figure class="image is-3by1">
          <img src="../assets/undraw_visual_data_b1wx.svg" />
        </figure> -->
        <div class="card">
          <div class="card-header">
            <p class="card-header-title">Select cars</p>
          </div>

          <div class="card-content">
            <div class="content">
              <form @submit.prevent="compareCars()">
                <div v-for="(car, index) in selectedCars" :key="index">
                  <div class="columns">
                    <div class="column is-3">
                      <b-select
                        placeholder="Select car"
                        icon="car"
                        required
                        v-model="selectedCars[index]"
                      >
                        <option
                          v-for="(car, index) in cars"
                          :key="index"
                          :value="car"
                          >{{ car.licensePlate }}, {{ car.model }}</option
                        >
                      </b-select>
                    </div>
                    <div class="column" v-if="canRemoveCarSelection">
                      <b-button
                        size="is-small"
                        type="is-danger"
                        icon-right="delete"
                        @click="removeCar(index)"
                      >
                      </b-button>
                    </div>
                  </div>
                </div>
                <b-field grouped class="has-margin-top-30">
                  <div class="control">
                    <b-button native-type="submit" type="is-primary"
                      >Compare</b-button
                    >
                  </div>
                  <div class="control">
                    <b-button
                      type="is-primary is-outlined"
                      @click="addNewCar"
                      :disabled="!canAddNewCarsToSelection"
                      >Add car</b-button
                    >
                  </div>
                  <div class="control">
                    <b-button type="is-primary is-outlined" @click="resetForm"
                      >Reset</b-button
                    >
                  </div>
                </b-field>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div v-if="areCarsAlreadyCompared">
      <section class="section">
        <div class="container">
          <div class="control has-margin-bottom-20">
            <b-button type="is-primary is-outlined" @click="resetForm()"
              >Reset comparison</b-button
            >
          </div>
          <h5 class="subtitle">Cars detail</h5>
          <hr class="solid" />
          <div class="columns is-multiline">
            <div
              class="column is-6-tablet is-4-desktop"
              v-for="(selectedCar, index) in selectedCars"
              :key="index"
            >
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">
                    <b-icon icon="car"></b-icon>
                    Car #{{ selectedCar.id }}
                  </p>
                </header>
                <div class="card-content">
                  <div class="content">
                    <label class="label">License plate</label>
                    <div class="is-flex">
                      <b-icon icon="credit-card"></b-icon>
                      <p>{{ selectedCar.licensePlate }}</p>
                    </div>

                    <label class="label">Make</label>

                    <div class="is-flex">
                      <b-icon icon="factory"></b-icon>
                      <p>{{ selectedCar.make }}</p>
                    </div>

                    <label class="label">Model</label>
                    <div class="is-flex">
                      <b-icon icon="account"></b-icon>
                      <p>{{ selectedCar.model }}</p>
                    </div>

                    <label class="label"> Mileage</label>
                    <div class="is-flex">
                      <b-icon icon="speedometer"></b-icon>
                      <p>{{ selectedCar.mileage }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <h5 class="subtitle">Total car expenses</h5>
          <hr class="solid" />
          <div class="columns">
            <div class="column">
              <OverallExpensesChart
                :carIds="selectedCars.map(carId => carId.id.toString())"
              />
            </div>
          </div>

          <h5 class="subtitle">Total expenses by types</h5>
          <hr class="solid" />
          <div class="columns is-multiline">
            <div
              class="column is-full-tablet is-half-desktop"
              v-for="(selectedCar, index) in selectedCars"
              :key="index"
            >
              <ExpensesByTypeChart
                :carId="selectedCar.id.toString()"
                :isComparison="true"
              />
            </div>
          </div>
        </div>
      </section>
    </div>

    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import Footer from '@/components/Footer.vue'
import PageTitle from '@/components/PageTitle.vue'
import { httpClient } from '@/api/httpClient.js'
import OverallExpensesChart from '@/components/OverallExpensesChart.vue'
import ExpensesByTypeChart from '@/components/ExpensesByTypeChart.vue'

export default {
  components: {
    Navbar,
    PageTitle,
    Footer,
    OverallExpensesChart,
    ExpensesByTypeChart
  },
  data() {
    return {
      isLoading: false,
      cars: [],
      selectedCars: [{}, {}],
      results: {
        carDetails: null,
        carExpenses: null,
        carExpensesTypes: null
      }
    }
  },
  created() {
    this.getCars()
  },
  computed: {
    areCarsAlreadyCompared() {
      return this.results.carDetails !== null
    },
    canRemoveCarSelection() {
      return this.selectedCars.length > 1
    },
    canAddNewCarsToSelection() {
      return this.selectedCars.length < 6
    }
  },
  methods: {
    getCars() {
      this.isLoading = true
      httpClient
        .get('cars')
        .then(res => {
          this.isLoading = false
          this.cars = res.data['hydra:member']
        })
        .catch(err => {
          this.isLoading = false
          this.$buefy.snackbar.open({
            message: `Error: ${err.message}`,
            type: 'is-danger',
            position: 'is-top'
          })
        })
    },
    addNewCar() {
      this.selectedCars = [...this.selectedCars, {}]
    },
    compareCars() {
      this.results.carDetails = true
    },
    resetForm() {
      this.results.carDetails = null
      this.selectedCars = [{}, {}]
    },
    removeCar(index) {
      if (this.selectedCars.length < 2) return
      this.selectedCars.splice(index, 1)
    }
  }
}
</script>
<style lang="scss" scoped>
hr.solid {
  border-top: 1px solid rgb(224, 224, 224);
}
</style>
